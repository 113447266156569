<!-- 景点详情页 -->
<template>
  <div class="scenicDetail">
    <div class="detailCover">
      <div class="swiper">
        <mt-swipe :auto="4000">
          <mt-swipe-item
            v-for="(items, index) in markerDetailData.imgVos"
            :key="index"
          >
            <img
              style="width: 100%; height: 100%"
              :src="$global.ImgUrl + items.pic"
              alt=""
            />
          </mt-swipe-item>
        </mt-swipe>
      </div>
      <div
        class="vr"
        v-if="markerDetailData.allview"
        @click="toVr(markerDetailData.allview)"
      >
        <div class="icoon">
          <img src="./image/icon_vr.svg" alt="" />
        </div>
        VR
      </div>
    </div>
    <div class="backBtn" @click="toGo()"></div>
    <!-- //显示内容 -->
    <div class="showContent">
      <!-- //头部内容 -->
      <div class="showTop">
        <div class="topTitle">
          <div class="titleName">{{ markerDetailData.name }}</div>
          <div class="titleBtn" v-if="markerDetailData.allview">
            {{ markerDetailData.allview }}
          </div>
        </div>
        <div class="topAddress">
          <div class="icon-adrress"></div>
          <p class="ps">{{ markerDetailData.address }}</p>
        </div>
      </div>
      <!-- 语音播报组件 -->
      <div class="intro" v-show="markerDetailData.audio">
        <div class="audioImg"></div>
        <div class="audio">
          <div class="audio-box">
            <audio
              :src="$global.ImgUrl + markerDetailData.audio"
              id="audioPlayer"
            ></audio>
            <!-- 音频播放模块 -->
            <div class="player-box">
              <div
                class="audio-left flex center"
                id="audioPlayer"
                @click="audioPlay"
              >
                <a v-if="!playing">
                  <img src="./image/icon_play(1).svg" alt="" />
                </a>
                <a v-else>
                  <img src="./image/push.svg" alt="" />
                </a>
              </div>
              <div class="audio-right flex center column">
                <!-- <p style="max-width: 536px;">Beta-B_Kan R. Gao.mp3</p> -->
                <div
                  class="progress-bar-bg"
                  id="progressBarBg"
                  @click="progressBarBg"
                >
                  <span
                    id="progressDot"
                    :style="'left:' + dotLeft + '%'"
                  ></span>
                  <div
                    class="progress-bar"
                    id="progressBar"
                    :style="'width:' + barWidth + '%'"
                  ></div>
                </div>
                <div class="audio-time">
                  <span class="audio-length-current" id="audioCurTime">{{
                    audioCurTime
                  }}</span
                  >/
                  <span class="audio-length-total">{{ duration }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="detailAll">
        <!-- 底部导航 -->
        <div class="introduceBtn">
          <div
            class="lanBtn"
            v-if="markerDetailData.relationId"
            @click="
              toDaolanFn(markerDetailData.jump, markerDetailData.relationId)
            "
          >
            <span class="icon-daolan"></span>
            <span>{{ lanData.tagName1 }}</span>
          </div>
          <div
            class="lanBtn"
            v-if="markerDetailData.jump"
            @click="
              toDaolanFn(markerDetailData.jump, markerDetailData.relationId)
            "
          >
            <span class="icon-daolan"></span>
            <span>{{ lanData.tagName1 }}</span>
          </div>
          <div class="hangBtn">
            <span
              class="icon-daohang"
              @click="toNavigationFn(markerDetailData)"
            ></span
            >{{ lanData.tagName2 }}
          </div>
        </div>
        <!-- 中间介绍 -->
        <div class="centerIntroduce">
          <div class="introText" v-html="markerDetailData.pointInfo"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import Vue from "vue";
import { Swipe, SwipeItem } from "mint-ui";
Vue.component(Swipe.name, Swipe, SwipeItem.name, SwipeItem);
import { markerDetailApi } from "../../request/api/index";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      pid: "",
      lanData: [],
      lat: "",
      lng: "",
      languageType: "",
      manyLanData: [
        {
          tagName: "线路介绍",
          tagName1: "导览",
          tagName2: "导航",
        },
        {
          tagName: "Introduce the line",
          tagName1: "tour",
          tagName2: "navigation",
        },
        {
          tagName: "노선 안내",
          tagName1: "깜짝놀",
          tagName2: "네비게이션",
        },
        {
          tagName: "路線案内",
          tagName1: "ガイドブック",
          tagName2: "カーナビ",
        },
      ],
      markerDetailData: [], //详情数据
      // 音频播放
      duration: "00:00", //音频总时长
      audioCurTime: "00:00", //音频当前时间
      playing: false, //音频播放中的状态
      barWidth: 0, //已播放条的宽度值
      dotLeft: 0, //进度点的left值
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    //跳转到导览
    toDaolanFn(jump, relationId) {
      if (jump) {
        window.location.href = jump;
      } else {
        if (relationId) {
          this.$router.push("/visitors/" + relationId);
        } else {
          return;
        }
      }
    },
    //跳转到导航
    toNavigationFn(item) {
      var ua = navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        //在微信就用微信地图
        this.commonFun.openLocation({
          name: item.name, // 位置名
          latitude: item.latitude, // 纬度，浮点数，范围为90 ~ -90
          longitude: item.longitude, // 经度，浮点数，范围为180 ~ -180。
          address: item.address, // 地址详情说明
          scale: 12, // 地图缩放级别,整形值,范围从1~28。默认为最大
          infoUrl: "", // 在查看位置界面底部显示的超链接,可点击跳转
        });
      }
    },
    toGo() {
      this.$router.go(-1);
    },
    // 点击进度条跳到指定点播放
    // PS：此处不要用click，否则下面的拖动进度点事件有可能在此处触发，此时e.offsetX的值非常小，会导致进度条弹回开始处
    progressBarBg(e) {
      let audio = document.getElementById("audioPlayer");
      if (!audio.paused || audio.currentTime != 0) {
        // let pgsWidth = $(".progress-bar-bg").width();
        let pgsWidth =
          document.getElementsByClassName("progress-bar-bg")[0].offsetWidth;
        let rate = e.offsetX / pgsWidth;
        audio.currentTime = audio.duration * rate;
        this.updateProgress(audio);
      }
    },
    // 鼠标拖动进度点时可以调节进度
    // 只有音乐开始播放后才可以调节，已经播放过但暂停了的也可以
    // 鼠标按下时
    progressDot(e) {
      let that = this;
      let audio = document.getElementById("audioPlayer");
      let dot = document.getElementById("progressDot");
      if (!audio.paused || audio.currentTime != 0) {
        let oriLeft = dot.offsetLeft;
        let mouseX = e.clientX;
        let maxLeft = oriLeft; // 向左最大可拖动距离
        let maxRight =
          document.getElementById("progressBarBg").offsetWidth - oriLeft; // 向右最大可拖动距离

        // 禁止默认的选中事件（避免鼠标拖拽进度点的时候选中文字）
        if (e.preventDefault) {
          e.preventDefault();
        } else {
          e.returnValue = false;
        }

        // 禁止事件冒泡
        if (e && e.stopPropagation) {
          e.stopPropagation();
        } else {
          window.event.cancelBubble = true;
        }

        // 开始拖动
        document.onmousemove = function (e) {
          let length = e.clientX - mouseX;
          if (length > maxRight) {
            length = maxRight;
          } else if (length < -maxLeft) {
            length = -maxLeft;
          }
          let pgsWidth =
            document.getElementsByClassName("progress-bar-bg")[0].width;
          let rate = (oriLeft + length) / pgsWidth;
          audio.currentTime = audio.duration * rate;
          that.updateProgress(audio);
        };

        // 拖动结束
        document.onmouseup = function () {
          document.onmousemove = null;
          document.onmouseup = null;
        };
      }
    },
    // 音频播放停止事件
    audioPlay() {
      let that = this;
      let audio = document.getElementById("audioPlayer");
      // 监听音频播放时间并更新进度条
      audio.addEventListener(
        "timeupdate",
        function () {
          that.updateProgress(audio);
        },
        false
      );

      // 监听播放完成事件
      audio.addEventListener(
        "ended",
        function () {
          that.audioEnded();
        },
        false
      );

      // 改变播放/暂停图片
      if (audio.paused) {
        // 开始播放当前点击的音频
        audio.play();
        that.playing = true;
      } else {
        audio.pause();
        that.playing = false;
      }
    },
    // 切换marker音频停止事件
    changeMarkAudioPlay() {
      let audio = document.getElementById("audioPlayer");
      // 改变播放/暂停图片
      if (audio.paused) {
        this.audioEnded();
        this.audioInit();
      } else {
        audio.pause();
        this.audioEnded();
        this.audioInit();
      }
    },
    // 更新进度条与当前播放时间 audio - audio对象
    updateProgress(audio) {
      let value = audio.currentTime / audio.duration;
      this.barWidth = value * 100;
      this.dotLeft = value * 100;
      this.audioCurTime = this.transTime(audio.currentTime);
      this.duration = this.transTime(audio.duration);
    },
    /**
     * 音频播放时间换算
     * @param {number} value - 音频当前播放时间，单位秒
     */
    transTime(value) {
      let time = "";
      let h = parseInt(value / 3600);
      value %= 3600;
      let m = parseInt(value / 60);
      let s = parseInt(value % 60);
      if (h > 0) {
        time = this.formatTime(h + ":" + m + ":" + s);
      } else {
        time = this.formatTime(m + ":" + s);
      }

      return time;
    },
    /**
     * 格式化时间显示，补零对齐
     * eg：2:4  -->  02:04
     * @param {string} value - 形如 h:m:s 的字符串
     */
    formatTime(value) {
      let time = "";
      let s = value.split(":");
      let i = 0;
      for (; i < s.length - 1; i++) {
        time += s[i].length == 1 ? "0" + s[i] : s[i];
        time += ":";
      }
      time += s[i].length == 1 ? "0" + s[i] : s[i];

      return time;
    },
    //  播放完成时把进度调回开始的位置
    audioEnded() {
      this.barWidth = 0;
      this.dotLeft = 0;
      this.playing = false;
      this.audioCurTime = "00:00";
    },
    audioInit() {
      let that = this;
      let audio = document.getElementById("audioPlayer");
      if (this.audioSrc != "") {
        audio.load();
        audio.addEventListener("canplay", function () {
          //监听audio是否加载完毕，如果加载完毕，则读取audio播放时间
          that.duration = that.transTime(audio.duration);
        });
      }
    },
    toVr(e) {
      window.location.href = e;
    },
    //marker详情页数据
    getMarkerDetailData() {
      markerDetailApi({
        languageType: this.languageType,
        pid: this.pid,
      }).then((res) => {
        // console.log(res);
        if (res.status == "200") {
          this.markerDetailData = res.data.data[0];
          console.log(this.markerDetailData);
        }
      });
    },
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.pid = this.$route.params.pid;
    this.languageType = this.$route.params.languageType;
    this.lanData = this.manyLanData[this.languageType - 1];
    this.getMarkerDetailData();
    this.audioInit();
  },
};
</script>
<style lang="less">
//marker的详情页
.scenicDetail {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 666;
  background: white;

  .detailCover {
    width: 100%;
    height: 45vh;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;

    .swiper {
      width: 100%;
      height: 100%;

      .mint-swipe-item {
        background: #fff;
      }

      .mint-swipe-indicators {
        position: absolute;
        bottom: 2.5rem;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    .vr {
      width: 6.1rem;
      height: 2.6rem;
      opacity: 0.9;
      background: #ffffff;
      border-radius: 2.1rem;
      position: absolute;
      bottom: 10rem;
      right: 2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.3rem;
      font-family: PingFang SC, PingFang SC-Medium;
      font-weight: 500;
      text-align: CENTER;
      color: #302e28;
      line-height: 1.3rem;
      letter-spacing: 0rem;

      .icoon {
        width: 1.8rem;
        height: 1.8rem;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .backBtn {
    width: 3.4rem;
    height: 3.4rem;
    position: absolute;
    top: 1.5rem;
    left: 1.5rem;
    background: url("./image/button_back.svg") no-repeat;
    background-size: 100%;
  }

  //显示内容
  .showContent {
    width: 100%;
    background: #fafafa;
    border-radius: 1.2rem 1.2rem 0rem 0rem;
    padding: 0.8rem 1.6rem 0.7rem;
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 2;

    .upIcon,
    .downIcon {
      width: 3.8rem;
      height: 2rem;
      margin: 0 auto 1.4rem;
      background: url("./image/up1.svg") no-repeat;
      background-size: 100%;
      flex-shrink: 0;
    }

    .downIcon {
      background: url("./image/down1.svg") no-repeat;
      background-size: 100%;
    }

    //头部内容
    .showTop {
      width: 100%;

      .topTitle {
        width: 100%;
        height: 3.8rem;
        display: flex;
        font-size: 2rem;
        align-items: flex-end;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;

        .titleName {
          height: 3.8rem;
          line-height: 2.8rem;
          font-size: 3.2rem;
          font-family: zihun96hao-huxiaoshoushu,
            zihun96hao-huxiaoshoushu-Regular;
          font-weight: 400;
          text-align: LEFT;
          color: #504847;
          line-height: 3.8rem;
          letter-spacing: -0.01rem;
        }

        .titleBtn {
          width: 5rem;
          height: 2.2rem;
          text-align: center;
          line-height: 2.2rem;
          margin-left: 1rem;
          background: #bd7c68;
          color: #fff;
          font-size: 1.3rem;
          font-family: PingFang SC, PingFang SC-Regular;
          font-weight: 400;
          margin-bottom: 0.3rem;
        }
      }

      .topAddress {
        height: 2;
        line-height: 2rem;
        margin: 1.2rem 0;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;

        .icon-adrress {
          width: 1.8rem;
          height: 1.8rem;
          margin-right: 0.4rem;
          background: url("./image/icon_address.svg") no-repeat;
          background-size: 100%;
          float: left;
        }

        .ps {
          height: 2;
          line-height: 2rem;
          font-size: 1.4rem;
          color: #5a6570;
        }
      }
    }

    //语音
    .intro {
      width: 100%;
      height: 6rem;
      border-radius: 0.8rem;
      background: #fff;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      position: relative;

      .audio {
        width: 100%;
        height: 100%;
        background: #fff;
        display: flex;
        align-items: center;
        box-sizing: border-box;

        .icon_play {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 3rem;
          height: 3rem;
          border-radius: 50%;
          background: #f85f5f;
        }

        .audio-box {
          width: 28rem;
          height: 4.8rem;

          .player-box {
            width: 100%;
            height: 4.8rem;
            justify-content: space-between;
            padding: 0 2.8rem 0 0.8rem;
            // margin: 0.4rem auto 0;
            display: flex;
            align-items: center;
            //   justify-content: center;
            background-color: #fff;

            .audio-left {
              /* float: left; */
              margin-right: 0.4rem;
              width: 3.2rem;
              height: 3.2rem;
              background: #615856;
              border-radius: 50%;
              display: flex;
              justify-content: center;
              align-items: center;

              a {
                width: 1.6rem;
                height: 1.6rem;

                img {
                  width: 100%;
                  height: 100%;
                }
              }
            }

            #audioPlayer {
              cursor: pointer;
            }

            .audio-right {
              flex: 1;
              width: 0;
              height: 100%;
              justify-content: center;
              padding-left: 0.5rem;

              .progress-bar-bg {
                width: 100%;
                background: #eee;
                position: relative;
                height: 0.2rem;
                cursor: pointer;
                border-radius: 0.2rem;
                margin-bottom: 0.6rem;
                margin-top: 2rem;

                span {
                  content: " ";
                  width: 1rem;
                  height: 1rem;
                  border-radius: 50%;
                  background-color: #fff;
                  border: 0.1rem solid #999999;
                  position: absolute;
                  left: 0;
                  top: 50%;
                  margin-top: -0.5rem;
                  margin-left: -0.5rem;
                  cursor: pointer;
                  z-index: 2;
                }

                .progress-bar {
                  background: #5a6570;
                  width: 0;
                  height: 0.2rem;
                  // margin-top: -0.01rem;
                  border-radius: 0.2rem;
                  position: absolute;
                  top: 50%;
                  left: 0;
                  transform: translateY(-50%);
                  z-index: 1;
                }
              }

              .audio-time {
                width: 100%;
                height: 1.7rem;
                overflow: hidden;
                color: #999999;
                justify-content: flex-end;
                // margin-top: 0.16rem;
                line-height: 1.7rem;
                font-size: 1.2rem;
                display: flex;
                align-items: center;
              }
            }
          }
        }
      }

      .audioImg {
        width: 8.3rem;
        height: 6rem;
        position: absolute;
        top: 0;
        right: 0;
        background: url("./image/house21.svg") no-repeat;
        background-size: 100%;
        z-index: 0;
      }
    }

    .detailAll {
      width: 100%;
      height: 29.9rem;

      //底部导航
      .introduceBtn {
        width: 28rem;
        height: 4.2rem;
        margin: 1.6rem auto;
        display: flex;
        justify-content: space-between;

        .lanBtn {
          width: 13.8rem;
          height: 4.2rem;
          line-height: 4.2rem;
          text-align: center;
          color: #fff;
          font-size: 1.3rem;
          background: url("./image/Group 555.svg") no-repeat;
          background-size: 100%;
          margin-right: 0.5rem;
          display: flex;
          justify-content: center;
          align-items: center;

          .icon-daolan {
            width: 1.6rem;
            height: 1.6rem;
            background: url("./image/icon_daolan.svg") no-repeat;
            background-size: 100%;
          }
        }

        .hangBtn {
          width: 13.8rem;
          height: 4.2rem;
          line-height: 4.2rem;
          text-align: center;
          color: #fff;
          font-size: 1.3rem;
          overflow: hidden;
          background: url("./image/Group 556.svg") no-repeat;
          background-size: 100%;
          margin: 0 auto;
          display: flex;
          justify-content: center;
          align-items: center;

          .icon-daohang {
            width: 1.6rem;
            height: 1.6rem;
            background: url("./image/icon_navigation.svg") no-repeat;
            background-size: 100%;
          }
        }
      }

      //中间介绍
      .centerIntroduce {
        width: 100%;
        height: 22.5rem;
        padding-bottom: 1rem;
        font-size: 1.4rem;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        color: #302d2a;
        overflow: auto;
      }
    }
  }
}
</style>